.Cancellation-maincontainer{
    padding-top:120px;
    padding-left:20px;
    display:flex;
    justify-content: center;
}

.CancellationPolicy-Heading{
    /* background-color: rgb(124 45 18 );  */
    background-color: #d2691e; 
    height: 350px;
    width: 450px;
    display: flex;
    align-items: center; 
    justify-content: center;
    text-align: center; 
    color: white;
    font-size: 24px;
    font-weight: bold; 
    border-radius: 15px;
    margin-bottom:20px;

}
