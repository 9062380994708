.Termsandcondition-maincontainer{
    padding-top:120px;
    padding-left:20px;
}


.Heading-Termscondition h1 {
    color: #d2691e;
    cursor: pointer;
    font-size: larger;
    font-weight: bolder;
    margin-bottom: 5px;
    margin-top: 10px;
    
}

.circle-list {
    list-style-type: disc; /* Ensures circular bullet points */
    padding-left: 20px;
  }
  
  .circle-list li {
    margin-bottom: 5px;
  }
  