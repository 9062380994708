.formconmember{
    display: flex;
    flex-direction: column;
    text-align: center;
    background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%),
            linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); 
    padding: 20px;
    width: 100%;
    max-width: 500px; 
    border-radius: 8px; 
    margin-bottom: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.checkoutpage{
    max-width: 500px;
    display: flex;
    border: 1px solid aliceblue;
    padding: 100px;
    background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%),
            linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); 
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
align-items: center;
margin-top: 10px;
margin-bottom: 10px;
   
}
.amountBtn{
    margin-top: 10px;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}
.address{
    font-size: 18px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}
.checkoutpage h2{
    margin-top: -20px;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif
}


/* Main container header */
.mainhead {
    display: flex;
    justify-content: center;
    margin: 0px;
    /* background-color: #b4a801; */
    /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%); */
    /* linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); */
    background-image: url("../../assets/SYC\ RANGUTSAV\ 2025.png");
    padding: 30px;
    color: #FFFFFF;
    height: 187px;
    align-items: center;
    flex-wrap: wrap; 
}

.mainhead1 {
    display: flex;
    justify-content: center;
    margin-top: 10px !important;
    margin: -30px;
    margin-bottom: 30px;
    /* background-color: #b4a801; */
    /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%); */
    /* linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); */
    background-image: url("../../assets/SYC\ RANGUTSAV\ 2025.png");
    padding: 30px;
    color: #FFFFFF;
    height: 187px;
    align-items: center;
    flex-wrap: wrap; 
}
.lefthead{
    margin-top: -25px;
}

.headingpart{
    margin-top: 8px;
}

.bodymainpart{
    padding: 20px;
    margin-top: 30px;
}

.mainhead p {    
        font-weight: 900;
        -webkit-text-stroke-width: 0.1px;
        -webkit-text-stroke-color: gray;
}

/* Left header */
.lefthead {
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap; 
}

.headbold {
    margin-left: 20px;
    font-size: 40px;
}

/* Right header */
.righthead {
    text-align: left;
}

.righthead p:nth-child(1) {
    width: 100%; 
    font-weight: 700;
    font-size: 24px;
}

.righthead p:nth-child(2) {
    font-weight: 700;
    margin-top: 20px;
    font-size: 20px;
}



/* Form container */
.form-con2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #D9D9D9;
    padding: 20px;
    width: 100%;
    max-width: 500px; 
    border-radius: 8px; 
    margin-bottom: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Individual input container */
.innercon2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; 
    /* width:1300px */
}
.Footerpart{
    /* margin-top: 92px; */
    /* height: 70px; */
    text-align: center;
    background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%);
    padding: 50px;
    margin-left: 40px;
    margin-right: 40px;
    color:white;
    margin-top: 22px;
}
.innerconformmember{
    text-align: start;
    margin-top: 5px;
    padding-bottom: 5px;
}


/* Submit button */
.innercon2 button {
    margin-top: 20px;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color:  #176AF0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}
.innerconformmember input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid orange;
    border-radius: 4px;
  }

 



.innercon2 button:hover {
    background-color: #0a5fe7;
}

/* Outer container */
.outercon2 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width:100%;

}

/* Responsive styles for tablets */
@media (max-width: 1210px) {
    .mainhead {
        flex-direction: column;
        margin: 0 20px;
        text-align: center;
        padding: 20px;
        height: auto; 
    }

    .headbold {
        font-size: 30px; 
        margin: 10px 0;
    }

    .righthead p:nth-child(1),
    .righthead p:nth-child(2) {
        font-size: 16px; 
    }

    .form-con2 {
        padding: 15px;
    }

    .innercon2 button {
        font-size: 14px;
        padding: 8px 15px;
    }
}

.form-image2{
    width:130px;
    height:130px;
    margin-left:185px;
    margin-bottom:50px;
    margin-top: -20px;
}

.input-equal2{
    margin-top:-50px;
}

/* Responsive styles for mobile */
@media (max-width: 480px) {
    .mainhead {
        flex-direction: column; 
        margin: 0 10px;
        text-align: center;
        padding: 10px;
    }

    .headbold {
        font-size: 24px; 
    }

    .righthead p:nth-child(1),
    .righthead p:nth-child(2) {
        font-size: 14px;
    }

    .form-con2 {
        width: 90%; 
        padding: 10px;
    }

    .innercon2 {
        margin-bottom: 10px;
    }

    .innercon2 button {
        font-size: 12px;
        padding: 6px 10px;
    }
}
