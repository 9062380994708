/* Loader.css */
.custom-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: White;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  