/* Main container header */
.mainhead {
    /* display: flex;
    justify-content: center; */
    /* margin: 0 40px; */
    /* background-color: #b4a801; */
    /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%); */
    /* linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); */
    background-image: url("../../assets/SYC\ RANGUTSAV\ 2025.png");   
    background-position: center; 
    background-size:cover;
    background-repeat: no-repeat;
    /* padding: 30px; */
    /* color: #FFFFFF; */
    height: 200px;
    max-width:1650px;
    /* position: relative; */
    /* top:20; */
    /* margin-top: 20px; */
    align-items: center;
    flex-wrap: wrap; 
    /* width: 1200px */
    /* background-color: #0a5fe7; */
}

.lefthead{
    margin-top: -25px;
}

.headingpart{
    margin-top: 8px;
}

.bodymainpart{
    padding: 20px;
    margin-top: 30px;
}

.mainhead p {    
    font-weight: 900;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: gray;
}

/* Left header */
.lefthead {
display: flex;
align-items: center;
text-align: center;
flex-wrap: wrap; 
}

.headbold {
margin-left: 20px;
font-size: 40px;
}

/* Right header */
.righthead {
text-align: left;
}

.righthead p:nth-child(1) {
width: 100%; 
font-weight: 700;
font-size: 24px;
}

.righthead p:nth-child(2) {
font-weight: 700;
margin-top: 20px;
font-size: 20px;
}




.bodyimage {
    position: relative; /* Ensure child elements can be positioned inside */
    width: 100%;
    height: 540px;
    overflow: hidden;
}

.backgroundimage {
    width: 100%;
    height: 540px;
}

.backgroundimage img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    display: block;
    padding-left: 40px;
    padding-right: 40px;
}

.cardcontainer2 {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;
}


.box2 {
      background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%);
    height: 300px;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}




.Errortick {
    background-color: red;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 50%; 
    color: white; 
    font-size: 16px; 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    margin-top:-80px;
}


/* .correctiontick {
    background-color: green;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 50%; 
    color: white; 
    font-size: 16px; 
    margin-bottom: 150px;
} */





/* .backgroundimage {
    position: relative;
    width: 100%;
    height: 540px; 
    overflow: hidden;
  }
  
  .backgroundimage img {
    max-width: 100%;
    width: 100%;
    height: 540px;
    object-fit: fill; 
    display: block;

    padding-left:40px;
    padding-right:40px;
  }

  .box{
    background-color: red;
    height: 300px;
    width:450px;
  
  } */
  






  .Footerpart{
    text-align: center;
    background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%);
    padding: 50px;
    margin-left: 40px;
    margin-right: 40px;
    color:white;
    /* margin-top: 22px; */
}

@media (max-width: 768px) {
    .mainhead {
        /* flex-direction: column;
        margin: 0 20px;
        text-align: center;
        padding: 20px; */
        height: 90px; 
        background-size: contain;
    }}

    @media (max-width: 480px) {
        .mainhead {
            /* flex-direction: column;  */
            /* margin: 0 10px; */
            /* text-align: center; */
            /* padding: 10px; */
            /* height:200px; */
            /* width:100%;
             */
             height:50px
        }} 