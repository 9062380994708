.navcolor{
    color: #d80c0f;
}

.navcolor:hover{
    color: #d80c0f;
}

.navbtn{
    background-color: #d80c0f;
}

.navcolor1{
    color: #d80c0f;
   
}
