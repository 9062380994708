.image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.image:hover {
  transform: scale(1.1);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalImage {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.testimonialsContainer {
  text-align: center;
  padding: 50px;
  background: #f8f8f8;
}

.title {
  font-size: 3rem;
  font-weight: bolder;
  color: #e5d30e;
  text-decoration: underline;
}

.subtitle {
  color: #666;
  margin-bottom: 40px;
}

.testimonialsGrid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonialCard {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
}

.testimonialImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.quote {
  font-style: italic;
  color: #555;
}

.name {
  font-weight: bold;
  color: #ff9800;
  margin-top: 10px;
}

.position {
  color: #666;
  font-size: 0.9rem;
}

.Footerpart {
  text-align: center;
  padding: 15px;
  background: #222;
  color: white;
  font-size: 14px;
  margin-top: 20px;
}





.images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px !important;
  margin-top: 20px;
  padding: 0px 20px 10px 50px;
}

.image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid #ddd;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.image:hover {
  transform: scale(1.2);
  border: 2px solid #f10ab3;
}

/* Full-screen modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalImage {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.modal:hover {
  cursor: pointer;
}

.mainhead {
  /* display: flex;
  justify-content: center; */
  /* margin: 0 40px; */
  /* background-color: #b4a801; */
  /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
  linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
  linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%); */
  /* linear-gradient(336deg, rgb(239, 235, 2), rgba(0,0,255,0) 70.71%); */
  background-image: url("../../assets/SYC\ RANGUTSAV\ 2025.png");   
  background-position: center; 
  background-size:cover;
  background-repeat: no-repeat;
  /* padding: 30px; */
  /* color: #FFFFFF; */
  height: 200px;
  max-width:1650px;
  /* position: relative; */
  /* top:20; */
  margin-top: 14vh;
  align-items: center;
  flex-wrap: wrap; 
  /* width: 1200px */
  /* background-color: #0a5fe7; */
}



.Footerpart{
  /* margin-top: 92px; */
  /* height: 70px; */
  text-align: center;
  background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
  linear-gradient(127deg, rgba(1, 185, 1, 0.8), rgba(0,255,0,0) 70.71%),
  linear-gradient(336deg, rgba(0, 0, 221, 0.8), rgba(0,0,255,0) 70.71%);
  padding: 50px;
  /* position: fixed;
  bottom:0; */
  width:100%;
  margin-top: 100px;
  /* margin-left: 40px;
  margin-right: 40px; */
  /* color:white;00 */
  /* position: relative; */
  /* bottom: 0; */
  /* margin-top: 22px; */
}



/* Responsive styles for tablets */
@media (max-width: 768px) {
  .mainhead {
      /* flex-direction: column;
      margin: 0 20px;
      text-align: center;
      padding: 20px; */
      height: 90px; 
      background-size: contain;
      
  }

}
/* Responsive styles for mobile */
@media (max-width: 480px) {
  .mainhead {
      /* flex-direction: column;  */
      /* margin: 0 10px; */
      /* text-align: center; */
      /* padding: 10px; */
      /* height:200px; */
      /* width:100%;
       */
       height:50px
       

  }
}

@media (max-width: 768px) {
  .images {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on smaller screens */
  }
}
@media (max-width: 480px) {
  .images {
    grid-template-columns: repeat(1, 1fr); /* 1 image per row on mobile */
  }
}